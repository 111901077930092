import { OrgId } from "@hex/common";
import React, { useCallback } from "react";
import styled from "styled-components";

import { Routes } from "../../route/routes";
import GoogleLogo from "../../static/integration/google-logo.inline.svg";
import {
  maybeIncludeTelemetry,
  useIsTelemetryEnabled,
} from "../../util/telemetry";
import { useHexFlag } from "../../util/useHexFlags";

import { LoginOption } from "./LoginOption";

/* eslint-disable tree-shaking/no-side-effects-in-initialization */
export const GoogleIcon = styled(GoogleLogo)`
  width: 22px;
  height: 22px;
`;

export interface GoogleLoginProps {
  authQueryParams: Record<string, string>;
  embedded: boolean;
  onSubmit: () => void;
  onComplete: () => void;
  orgId?: OrgId;
}

export const GoogleLogin: React.ComponentType<GoogleLoginProps> = React.memo(
  function GoogleLogin({
    authQueryParams,
    embedded,
    onComplete,
    onSubmit,
    orgId,
  }) {
    const telemetryTimeoutMs = useHexFlag("telemetry-timeout");
    const useStytchCNAME = useHexFlag("telemetry-use-stytch-cname");
    const isTelemetryEnabled = useIsTelemetryEnabled();
    const googleCallback = useCallback(async () => {
      onSubmit();
      const params = await maybeIncludeTelemetry(
        authQueryParams,
        isTelemetryEnabled,
        telemetryTimeoutMs,
        useStytchCNAME,
      );

      const url = Routes.GOOGLE_AUTH.getUrl({
        orgId,
        params,
      });

      if (embedded) {
        window.open(url, "_blank");
        onComplete();
      } else {
        window.location.href = url;
      }
    }, [
      authQueryParams,
      embedded,
      orgId,
      isTelemetryEnabled,
      onSubmit,
      onComplete,
      telemetryTimeoutMs,
      useStytchCNAME,
    ]);

    return (
      <LoginOption icon={<GoogleIcon />} onClick={googleCallback}>
        Log in with <span>Google</span>
      </LoginOption>
    );
  },
);
