import { OrgId } from "@hex/common";
import React from "react";

import { useOrgDetailsQuery } from "../../route/LoginRoute.generated";

export interface OrgNameDisplayProps {
  orgId: OrgId;
}

export const OrgNameDisplay: React.ComponentType<OrgNameDisplayProps> =
  React.memo(function OrgNameDisplay({ orgId }) {
    const { data } = useOrgDetailsQuery({
      variables: { orgId },
    });
    return <>{data?.publicOrgDetails?.displayName}</>;
  });
