import { OrgId } from "@hex/common";
import React, { useCallback } from "react";
import styled from "styled-components";

import { Routes } from "../../route/routes";
import MicrosoftLogo from "../../static/integration/microsoft-logo.inline.svg";
import {
  maybeIncludeTelemetry,
  useIsTelemetryEnabled,
} from "../../util/telemetry";
import { useHexFlag } from "../../util/useHexFlags";

import { LoginOption } from "./LoginOption";

export const MicrosoftIcon = styled(MicrosoftLogo)`
  width: 22px;
  height: 22px;
`;

export interface AzureLoginProps {
  authQueryParams: Record<string, string>;
  embedded: boolean;
  onComplete: () => void;
  onSubmit: () => void;
  orgId?: OrgId;
}

export const AzureLogin: React.ComponentType<AzureLoginProps> = React.memo(
  function AzureLogin({
    authQueryParams,
    embedded,
    onComplete,
    onSubmit,
    orgId,
  }) {
    const telemetryTimeoutMs = useHexFlag("telemetry-timeout");
    const useStytchCNAME = useHexFlag("telemetry-use-stytch-cname");
    const isTelemetryEnabled = useIsTelemetryEnabled();
    const azureCallback = useCallback(async () => {
      onSubmit();
      const params = await maybeIncludeTelemetry(
        authQueryParams,
        isTelemetryEnabled,
        telemetryTimeoutMs,
        useStytchCNAME,
      );

      const url = Routes.AZURE_AUTH.getUrl({
        orgId,
        params,
      });
      if (embedded) {
        window.open(url, "_blank");
        onComplete();
      } else {
        window.location.href = url;
      }
    }, [
      authQueryParams,
      embedded,
      orgId,
      isTelemetryEnabled,
      onSubmit,
      onComplete,
      telemetryTimeoutMs,
      useStytchCNAME,
    ]);

    return (
      <LoginOption icon={<MicrosoftLogo />} onClick={azureCallback}>
        Log in with <span>Microsoft</span>
      </LoginOption>
    );
  },
);
