import { Classes } from "@blueprintjs/core";
import styled from "styled-components";

import { HexButton } from "../../hex-components";

export const LoginOption = styled(HexButton)`
  && {
    width: 100%;
    height: 40px;
  }

  .${Classes.BUTTON_TEXT} {
    span {
      font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
    }
  }
`;
