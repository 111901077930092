import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import type {
  Components,
  Options as ReactMarkdownOptions,
} from "react-markdown";
import type { TransformLinkTarget } from "react-markdown/lib/ast-to-react";
import type { PluggableList } from "react-markdown/lib/react-markdown";
import rehypeAutolinkHeadings from "rehype-autolink-headings";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import rehypeSlug from "rehype-slug";
import remarkGFM from "remark-gfm";
import remarkMath from "remark-math";
import styled from "styled-components";

// needed for rehype-katex to properly render
import "katex/dist/katex.min.css";

import { useScrollOffset } from "../../hooks/useScrollOffset";
import { MarkdownSize } from "../../theme/common/theme";
import { CyData } from "../../util/cypress";
import { createRequestHeaders } from "../../util/headers.js";

import { MarkdownSanitizeSchema } from "./MarkdownSanitizeSchema";
import {
  MARKDOWN_AND_RICH_TEXT_HEADING_LINK_CLASSNAME,
  ScrollToLinkAnchor,
} from "./ScrollToLinkAnchor";
import { SyntaxHighlighter } from "./SyntaxHighlighter";

const transformLinkTarget: TransformLinkTarget = (href, _children, _title) =>
  href.startsWith("#")
    ? // if a hash link, make sure we don't open another tab
      "_self"
    : "_blank";

const anchorRenderer: Components["a"] = ({
  children,
  className,
  node: _node,
  ...props
}) =>
  className?.includes(MARKDOWN_AND_RICH_TEXT_HEADING_LINK_CLASSNAME) ? (
    <ScrollToLinkAnchor className={className} {...props} />
  ) : (
    <a className={className} {...props}>
      {children}
    </a>
  );

// see https://github.com/remarkjs/react-markdown#use-custom-components-syntax-highlight
const codeRenderer: Components["code"] = ({
  children,
  className,
  inline,
  node: _node,
  ...props
}) =>
  inline !== true ? (
    <SyntaxHighlighter
      isInAppView={true}
      language={className?.match(/language-(\w+)/)?.[1]}
      {...props}
    >
      {String(children).replace(/\n$/, "")}
    </SyntaxHighlighter>
  ) : (
    <code className={className} {...props}>
      {children}
    </code>
  );

/**
 * Predownloads images hosted by us and renders them via createObjectURL.
 * This allows us to pipe in bearer token auth for anonymous users without cookies.
 */
export const PredownloadImageTag: React.FunctionComponent<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
> = React.memo(function PredownloadedImageRenderer({ src, ...props }) {
  const [objectUrl, setObjectUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const makeObjectUrl = async (originUrl: string): Promise<void> => {
      const response = await fetch(originUrl, {
        headers: createRequestHeaders(),
      });
      if (response.ok) {
        blobUrl = window.URL.createObjectURL(await response.blob());
      }
      setObjectUrl(blobUrl);
      setIsLoading(false);
    };

    let blobUrl: string | null = null;

    if (src && src.startsWith("/api/v1/file/")) {
      void makeObjectUrl(src);
    } else {
      setObjectUrl(src || null);
      setIsLoading(false);
    }

    return () => {
      if (blobUrl) {
        window.URL.revokeObjectURL(blobUrl);
      }
    };
  }, [src]);

  if (isLoading) {
    // todo(nwold): should this be a loading spinner?
    return <img {...props} />;
  } else {
    // @ts-expect-error - null is a valid value here, it just shows the broken image icon
    return <img src={objectUrl} {...props} />;
  }
});

const imageRenderer: Components["img"] = ({ ...props }) => (
  <PredownloadImageTag {...props} />
);

const StyledHeaderBase = styled.h1<{ scrollOffset: number }>`
  scroll-margin-top: ${(props) => `${props.scrollOffset}px`};
`;

const withOffset = <T extends "h1" | "h2" | "h3" | "h4" | "h5" | "h6">(
  headingType: T,
  scrollOffset: number,
): Components[T] => {
  return function HeaderWithScrollMargin({ children, node: _node, ...props }) {
    return (
      <StyledHeaderBase
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- ts isn't inferring this type correctly and thinks `as?: never | undefined`
        as={headingType as any}
        scrollOffset={scrollOffset}
        {...props}
      >
        {children}
      </StyledHeaderBase>
    );
  };
};

const components: ReactMarkdownOptions["components"] = {
  a: anchorRenderer,
  code: codeRenderer,
  img: imageRenderer,
};

const createComponentsWithOffsets: (
  scrollOffset: number,
) => ReactMarkdownOptions["components"] = (scrollOffset) => ({
  h1: withOffset("h1", scrollOffset),
  h2: withOffset("h2", scrollOffset),
  h3: withOffset("h3", scrollOffset),
  h4: withOffset("h4", scrollOffset),
  h5: withOffset("h5", scrollOffset),
  h6: withOffset("h6", scrollOffset),
});

const remarkPlugins: PluggableList = [
  // adds strike through, table support, tasklists, and some other goodies
  remarkGFM,
  // allows parsing of math in markdown
  [remarkMath, { singleDollarTextMath: false }],
];

const rehypePluginsNoAutolink: PluggableList = [
  // allows HTML to pass through
  rehypeRaw,
  // make all the above plugins safe MUST BE AFTER USER HTML - https://github.com/rehypejs/rehype-sanitize#security
  [rehypeSanitize, MarkdownSanitizeSchema],
  // pretty renders math
  rehypeKatex,
];

// this plugin chokes on array spread syntax
// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
const rehypePluginsWithAutolink: PluggableList = rehypePluginsNoAutolink.concat(
  [
    // add ids to headings, required for rehype-autolink-headings
    rehypeSlug,
    // allow direct linking to headings
    [
      rehypeAutolinkHeadings,
      {
        behavior: "append",
        properties: {
          ariaHidden: "true",
          tabIndex: "-1",
          class: MARKDOWN_AND_RICH_TEXT_HEADING_LINK_CLASSNAME,
        },
      },
    ],
  ],
);

const MarkdownBody = styled(ReactMarkdown)<{ $size: MarkdownSize }>`
  overflow: hidden;
  ${({ $size, theme }) => theme.markdownStyles($size)}
`;

export interface MarkdownRendererProps {
  size: MarkdownSize;
  className?: string;
  children: string;
  disableAutolink?: boolean;
}

export const MarkdownRenderer: React.ComponentType<MarkdownRendererProps> =
  React.memo(function MarkdownRenderer({
    children,
    className,
    disableAutolink = false,
    size,
  }) {
    const maybeScrollOffset = useScrollOffset();
    const maybeComponentsWithOffsets = React.useMemo(() => {
      return maybeScrollOffset
        ? createComponentsWithOffsets(maybeScrollOffset)
        : {};
    }, [maybeScrollOffset]);
    return (
      <MarkdownBody
        $size={size}
        className={className}
        components={{
          ...components,
          ...maybeComponentsWithOffsets,
        }}
        data-cy={CyData.MARKDOWN_CONTAINER}
        linkTarget={transformLinkTarget}
        rehypePlugins={
          disableAutolink ? rehypePluginsNoAutolink : rehypePluginsWithAutolink
        }
        remarkPlugins={remarkPlugins}
      >
        {children}
      </MarkdownBody>
    );
  });
