import { rgba } from "polished";
import React from "react";
import styled from "styled-components";

import BkgNoise from "../../static/image/bkg-noise.png";

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 30px 50px;
  overflow-x: hidden;
  position: relative;
  overflow-y: auto;
  column-gap: 108px;
  background-color: ${({ theme }) => theme.marketingColors.BACKGROUND};
  background-image: linear-gradient(
    to left,
    transparent 145px,
    ${({ theme }) => rgba(theme.marketingColors.ROSE_QUARTZ, 0.2)} 145px,
    transparent 146px
  );
  background-position: center;
  background-size: 290px 290px;

  &::before {
    content: "";
    display: block;
    pointer-events: none;
    position: absolute;
    top: 0;
    opacity: 0.05;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${BkgNoise});
    background-repeat: repeat;
    background-size: 200px 200px;
  }
`;

export const LoginHeader = styled.h1`
  flex: none;
  margin: 0;
  margin-bottom: 30px;

  color: ${({ theme }) => theme.marketingColors.OPAL};
  font-weight: 700;
  font-size: 38px;
  line-height: 44px;
  letter-spacing: -2px;
  text-align: center;

  > span {
    box-shadow: inset 0 -1px ${({ theme }) => theme.marketingColors.BACKGROUND};
  }
`;

export const LoginElements = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 390px;
  height: 100%;
  min-height: 0;

  &:before,
  &:after {
    margin: auto;

    content: "";
  }
`;

const SuperText = styled.div`
  margin-top: 30px;

  color: ${({ theme }) => theme.marketingColors.ROSE_QUARTZ};
  font-size: ${({ theme }) => theme.fontSize.SMALL};

  font-family: ${({ theme }) => theme.fontFamily.MONO};
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
`;

export interface LoginPageProps {
  children?: React.ReactNode;
  orgName?: React.ReactNode;
  rightElement?: React.ReactNode;
  embedded: boolean;
  superText?: string;
}

export const LoginPage: React.ComponentType<LoginPageProps> = React.memo(
  function LoginPage({ children, embedded, orgName, rightElement, superText }) {
    const useOrgHeader = orgName != null;

    return (
      <LoginContainer>
        <LoginElements>
          {superText && <SuperText>{superText}</SuperText>}
          {!embedded && (
            <>
              {useOrgHeader ? (
                <LoginHeader>{orgName}</LoginHeader>
              ) : (
                <LoginHeader>Log in</LoginHeader>
              )}
            </>
          )}
          {children}
        </LoginElements>
        {rightElement}
      </LoginContainer>
    );
  },
);
