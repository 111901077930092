import React, {
  MouseEventHandler,
  useCallback,
  useLayoutEffect,
  useRef,
} from "react";
import { useLocation } from "react-router";

import { useToaster } from "../common/Toasts";
import { LinkIcon } from "../icons/CustomIcons";

export interface ScrollToLinkAnchorProps {
  className?: string;
}

// this specific class name is used by MarkdownRenderer to specify styles for
// heading links (See MarkdownRenderer.tsx) and some styles are dependent on
// this class name (see sharedThemeStyles.ts). We want to make sure that the
// styles between markdown and rich text are consistent.
export const MARKDOWN_AND_RICH_TEXT_HEADING_LINK_CLASSNAME = "heading-link";

export const ScrollToLinkAnchor: React.ComponentType<
  ScrollToLinkAnchorProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
> = React.memo(function ScrollToLinkAnchor({ className, onClick, ...props }) {
  const location = useLocation();
  const linkRef = useRef<HTMLAnchorElement>(null);
  const toaster = useToaster();

  useLayoutEffect(() => {
    if (location.hash === props.href) {
      linkRef.current?.scrollIntoView();
    }
  }, [location.hash, props.href]);

  const onLinkClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    async (evt) => {
      onClick?.(evt);

      if (props.href != null) {
        window.location.hash = props.href;
        await navigator.clipboard.writeText(window.location.href);
        toaster.show({
          message: "Link copied to clipboard",
        });
      }
    },
    [onClick, props.href, toaster],
  );

  return (
    <a ref={linkRef} className={className} onClick={onLinkClick} {...props}>
      <LinkIcon />
    </a>
  );
});
