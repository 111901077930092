import { Icon, Intent } from "@blueprintjs/core";
import React from "react";
import styled, { FlattenSimpleInterpolation } from "styled-components";

import { HexTooltip } from "../../../hex-components";

import { InputFormLabel } from "./InputFormLabel";

export const BaseInputConfigurationContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FormLabelContainer = styled.div`
  flex: none;
  margin-right: 8px;
  padding: 5px 0px;
`;

const InputContainer = styled.div`
  position: relative;

  flex: auto;
  min-width: 0;
`;

const StyledTooltip = styled(HexTooltip)<{
  iconStyles?: FlattenSimpleInterpolation;
}>`
  position: absolute;
  top: 4px;
  right: 5px;

  ${({ iconStyles }) => iconStyles}
`;

export interface BaseInputConfigurationProps {
  label?: string;
  labelForId?: string;
  info?: string;
  infoLocation?: "tooltip" | "inline";
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  error?: string;
  iconStyles?: FlattenSimpleInterpolation;
}

export const BaseInputConfiguration: React.ComponentType<BaseInputConfigurationProps> =
  React.memo(function BaseInputConfiguration({
    children,
    className,
    disabled,
    error,
    iconStyles,
    info,
    infoLocation,
    label,
    labelForId,
  }) {
    return (
      <BaseInputConfigurationContainer className={className}>
        {label != null && (
          <FormLabelContainer>
            <InputFormLabel
              disabled={disabled}
              info={info}
              infoLocation={infoLocation}
              label={label}
              labelForId={labelForId}
            />
          </FormLabelContainer>
        )}
        <InputContainer>
          {children}
          {error && (
            <StyledTooltip
              autoFocus={false}
              content={error}
              disabled={error == null || disabled}
              enforceFocus={false}
              iconStyles={iconStyles}
              openOnTargetFocus={false}
              placement="right"
            >
              <Icon icon="error" intent={Intent.DANGER} />
            </StyledTooltip>
          )}
        </InputContainer>
      </BaseInputConfigurationContainer>
    );
  });
