import { OutputType, getPreferredMimeType } from "@hex/common";

import { OutputMP } from "../../redux/slices/appSessionMPSlice";

const getDataMap = (
  outputFragment: OutputMP,
): { [mimeType: string]: string } => {
  return outputFragment.frontendOutputContents.reduce<{
    [mimeType: string]: string;
  }>((map, outputContents) => {
    map[outputContents.mimeType] = outputContents.contents;
    return map;
  }, {});
};

const getPreferredMimeTypeFromOutput = (
  dataMap: { [mimeType: string]: string },
  outputFragment: OutputMP,
): string | undefined => {
  const preferredMimeType = getPreferredMimeType(Object.keys(dataMap));
  if (preferredMimeType) {
    return preferredMimeType;
  }
  if (outputFragment.outputType === OutputType.ERROR) {
    return "traceback";
  }
};

export const getPreferredOutputContents = (
  outputFragment: OutputMP,
): {
  preferredContents: string;
  preferredMimeType: string | undefined;
} => {
  const dataMap = getDataMap(outputFragment);
  const preferredMimeType = getPreferredMimeTypeFromOutput(
    dataMap,
    outputFragment,
  );
  const preferredContents = preferredMimeType ? dataMap[preferredMimeType] : "";

  return { preferredContents, preferredMimeType };
};
