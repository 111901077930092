import {
  COLUMN_AGGREGATIONS_MIMETYPE,
  DISPLAY_TABLE_MIMETYPE,
  HEX_CHART_MIMETYPE,
} from "@hex/common";

import { OutputMP } from "../../redux/slices/appSessionMPSlice";
import { ElementType } from "../elements/ElementType";
import { isAltairChart } from "../renderer/html/parseChart";
import { isTableHtmlString } from "../renderer/table/html-table/parseTable";

import { getPreferredOutputContents } from "./preferredOutputContents";

export const parseOutputType = (
  outputFragment: OutputMP,
): ElementType | null => {
  const { preferredContents, preferredMimeType } =
    getPreferredOutputContents(outputFragment);

  if (preferredMimeType == null) {
    return null;
  }

  // TODO(ccolgrove): we might want to make this concept map more closely to mimetype. Not clear
  // what value we get out of collapsing all charts into a single type.
  if (preferredMimeType === DISPLAY_TABLE_MIMETYPE) {
    return "display_table";
  } else if (preferredMimeType === "text/html") {
    if (isAltairChart(preferredContents)) {
      return "chart";
    } else if (isTableHtmlString(preferredContents)) {
      return "table";
    } else {
      return "html";
    }
  } else if (preferredMimeType === "text/plain") {
    const maybeNumber = parseInt(preferredContents);
    return isNaN(maybeNumber) ? "string" : "number";
  } else if (preferredMimeType === "traceback") {
    // TODO(ccolgrove): make this its own element type
    return "html";
  } else if (preferredMimeType.startsWith("image/")) {
    return "image";
  } else if (
    preferredMimeType.includes("vega") ||
    preferredMimeType === HEX_CHART_MIMETYPE
  ) {
    return "chart";
  } else if (preferredMimeType === COLUMN_AGGREGATIONS_MIMETYPE) {
    return "column_aggregations";
  } else {
    return null;
  }
};
