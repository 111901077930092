import { OrgId } from "@hex/common";
import React, { useCallback } from "react";

import { Routes } from "../../route/routes";
import {
  maybeIncludeTelemetry,
  useIsTelemetryEnabled,
} from "../../util/telemetry";
import { useHexFlag } from "../../util/useHexFlags";

import { LoginOption } from "./LoginOption";

export interface SsoLoginProps {
  authQueryParams: Record<string, string>;
  embedded: boolean;
  onComplete: () => void;
  onSubmit: () => void;
  orgId: OrgId;
  className?: string;
}

export const SsoLogin: React.ComponentType<SsoLoginProps> = React.memo(
  function SsoLogin({
    authQueryParams,
    className,
    embedded,
    onComplete,
    onSubmit,
    orgId,
  }) {
    const telemetryTimeoutMs = useHexFlag("telemetry-timeout");
    const useStytchCNAME = useHexFlag("telemetry-use-stytch-cname");
    const isTelemetryEnabled = useIsTelemetryEnabled();
    const ssoCallback = useCallback(async () => {
      onSubmit();
      const params = await maybeIncludeTelemetry(
        authQueryParams,
        isTelemetryEnabled,
        telemetryTimeoutMs,
        useStytchCNAME,
      );

      const ssoUrl = Routes.SSO_AUTH.getUrl({
        orgId,
        params,
      });

      if (embedded) {
        window.open(ssoUrl, "_blank");
        onComplete();
      } else {
        window.location.href = ssoUrl;
      }
    }, [
      embedded,
      orgId,
      authQueryParams,
      isTelemetryEnabled,
      onSubmit,
      onComplete,
      telemetryTimeoutMs,
      useStytchCNAME,
    ]);
    return (
      <LoginOption className={className} onClick={ssoCallback}>
        Log in with <span>SSO</span>
      </LoginOption>
    );
  },
);
