import { GLOBAL_ORG_ID, OrgId } from "@hex/common";
import { rgba } from "polished";
import React from "react";
import styled from "styled-components";

import { HexCallout } from "../../hex-components/HexCallout.js";
import { useToggleState } from "../../hooks/useToggleState";
import { useOrgDetailsQuery } from "../../route/LoginRoute.generated";
import { Routes } from "../../route/routes";
import { allowGlobalSso } from "../../util/data";
import { LoadingView } from "../../views/LoadingView";

import { AzureLogin } from "./AzureLogin";
import { EmailLogin } from "./EmailLogin";
import { GoogleLogin } from "./GoogleLogin";
import { SsoLogin } from "./SsoLogin";

export const LoginCardContainer = styled.div`
  padding: 25px;

  background-color: ${({ theme }) => theme.backgroundColor.MUTED};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow.CARD};
`;

export const LoginCardLoading = styled(LoadingView)`
  position: absolute;
  top: 0;
  left: 0;

  background-color: ${({ theme }) =>
    rgba(theme.marketingColors.BACKGROUND, 0.2)};

  transition: background-color 0.5s ease;
`;

export const LoginButtonContainer = styled.div`
  margin-bottom: 10px;
`;

export const EmailFormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 17px 0;

  > span {
    margin: 0 10px;

    opacity: 0.5;
  }

  &::before,
  &::after {
    flex: 1 1 auto;
    height: 1px;

    background-color: ${({ theme }) => theme.marketingColors.BACKGROUND};
    opacity: 0.1;

    content: "";
  }
`;

interface LoginCardProps {
  orgId: OrgId | undefined;
  authQueryParams: Record<string, string>;
  embedded: boolean;
}

export const LoginCard: React.ComponentType<LoginCardProps> = React.memo(
  function LoginCard({ authQueryParams, embedded, orgId }) {
    const { data, loading } = useOrgDetailsQuery({
      variables: { orgId: orgId ?? GLOBAL_ORG_ID },
    });

    const useEmbeddedRedirect =
      data?.publicOrgDetails?.ssoEnforced &&
      data.publicOrgDetails.allowsIframing;

    const [
      userSubmitted,
      ,
      { setFalse: authnRequestCompleted, setTrue: authnRequested },
    ] = useToggleState(false);

    if (embedded && !useEmbeddedRedirect) {
      authQueryParams["redirectTo"] = Routes.href(
        orgId ?? GLOBAL_ORG_ID,
        false,
        Routes.LOGIN_SUCCESS,
      );
    }

    if (data == null || data.publicOrgDetails == null) {
      if (loading) {
        return null;
      } else {
        return (
          <LoginCardContainer
            css={`
              text-align: center;
            `}
          >
            We couldn&apos;t find this workspace
          </LoginCardContainer>
        );
      }
    }

    const { azureAuth, displayName, googleAuth, ssoEnabled, ssoEnforced } =
      data.publicOrgDetails;

    // Google SSO is not supported in iOS WebViews
    const googleSsoSupported = !(
      /linkedin|instagram|twitter|messengerforios/i.test(
        navigator.userAgent.toLowerCase(),
      ) && navigator.userAgent.includes("iPhone")
    );

    return (
      <>
        {!googleSsoSupported && (
          <HexCallout
            $size="small"
            css={`
              margin-bottom: 16px;
            `}
            intent="warning"
          >
            Looks like you&apos;re in an in-app web browser. For the best
            experience (including login with Google) we recommend navigating to
            app.hex.tech/signup from your device&apos;s browser application
            (e.g. Chrome).
          </HexCallout>
        )}
        <LoginCardContainer
          css={`
            position: relative;
          `}
        >
          {ssoEnabled && (orgId || allowGlobalSso) && (
            <LoginButtonContainer>
              <SsoLogin
                authQueryParams={authQueryParams}
                embedded={embedded}
                orgId={orgId ?? GLOBAL_ORG_ID}
                onComplete={authnRequestCompleted}
                onSubmit={authnRequested}
              />
            </LoginButtonContainer>
          )}
          {!ssoEnforced && googleAuth && googleSsoSupported && (
            <LoginButtonContainer>
              <GoogleLogin
                authQueryParams={authQueryParams}
                embedded={embedded}
                orgId={orgId}
                onComplete={authnRequestCompleted}
                onSubmit={authnRequested}
              />
            </LoginButtonContainer>
          )}
          {!ssoEnforced && azureAuth && (
            <LoginButtonContainer>
              <AzureLogin
                authQueryParams={authQueryParams}
                embedded={embedded}
                orgId={orgId}
                onComplete={authnRequestCompleted}
                onSubmit={authnRequested}
              />
            </LoginButtonContainer>
          )}
          {!(embedded && ssoEnforced) && (
            <>
              {(googleAuth || azureAuth) && (
                <EmailFormHeader>
                  <span>Or, log in with email</span>
                </EmailFormHeader>
              )}
              <EmailLogin
                authQueryParams={authQueryParams}
                displayName={displayName}
                embedded={embedded}
                orgId={orgId}
                ssoEnforced={ssoEnforced}
                onComplete={authnRequestCompleted}
                onSubmit={authnRequested}
              />
            </>
          )}
          {userSubmitted && <LoginCardLoading />}
        </LoginCardContainer>
      </>
    );
  },
);
