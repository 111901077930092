/* eslint-disable tree-shaking/no-side-effects-in-initialization */
import prismComponents from "prismjs/components.json";
import { defaultSchema } from "rehype-sanitize";

type SanitizeSchema = typeof defaultSchema;
type SanitizeAttributeValue =
  Required<SanitizeSchema>["attributes"][string][number];
type AttributeArrayValue = [string, ...(string | number | boolean)[]];

export const MarkdownSanitizeSchema: SanitizeSchema =
  patchSanitizeSchemaForCode(patchSanitizeSchemaForRemarkMath(defaultSchema));

/**
 * Combines an existing allowed attribute list
 * with a new one. Handles merging existing attributes value tuples
 * to make sure we don't drop any previously allowed values.
 */
function patchSanitizeAttributes(
  oldAttributes: SanitizeAttributeValue[],
  attributesToAdd: SanitizeAttributeValue[],
): SanitizeAttributeValue[] {
  let newAttributes = [...oldAttributes];

  for (const attribute of attributesToAdd) {
    if (typeof attribute === "string") {
      if (newAttributes.find((a) => a === attribute || a[0] === attribute)) {
        // do nothing if its already included
      } else {
        // otherwise add it
        newAttributes.push(attribute);
      }
    } else {
      // If the attribute is limited to only specific values
      // we need to merge it with the preivous set of values if it exists
      const [attributeName, ...newValues] = attribute;
      const oldAttributeValue = newAttributes.find(
        (a) => a[0] === attributeName,
      );

      const baseAttributeValue: AttributeArrayValue = Array.isArray(
        oldAttributeValue,
      )
        ? oldAttributeValue
        : ([attributeName] as AttributeArrayValue);

      const newAttributeValue: AttributeArrayValue = [
        ...baseAttributeValue,
        ...newValues,
      ];

      newAttributes = newAttributes.filter(
        (a) => a !== attributeName && a[0] !== attributeName,
      );
      newAttributes.push(newAttributeValue);
    }
  }

  return newAttributes;
}

/**
 * Add the class names that code fences
 * to the allow list (allows all languages that prism supports)
 */
function patchSanitizeSchemaForCode(schema: SanitizeSchema): SanitizeSchema {
  const resolvedLanguages = Object.entries(prismComponents.languages).flatMap(
    ([language, defition]) => {
      const alias = (defition as { alias?: string | string[] }).alias || [];
      return [language, ...(typeof alias === "string" ? [alias] : alias)];
    },
  );
  const languageClassNames = resolvedLanguages.map(
    (lang) => `language-${lang}`,
  );
  return {
    ...schema,
    attributes: {
      ...schema.attributes,
      code: patchSanitizeAttributes(
        (schema.attributes != null ? schema.attributes.code : undefined) ?? [],
        [["className", ...languageClassNames]],
      ),
    },
  };
}

/**
 * Add the class names that remark-math adds
 * to the allow list
 */
function patchSanitizeSchemaForRemarkMath(
  schema: SanitizeSchema,
): SanitizeSchema {
  return {
    ...schema,
    attributes: {
      ...schema.attributes,
      div: patchSanitizeAttributes(
        (schema.attributes != null ? schema.attributes.div : undefined) ?? [],
        [["className", "math", "math-display"]],
      ),
      span: patchSanitizeAttributes(
        (schema.attributes != null ? schema.attributes.span : undefined) ?? [],
        [["className", "math", "math-inline"]],
      ),
    },
  };
}
