import React from "react";
import styled from "styled-components";
import { Placement } from "@blueprintjs/core";
import { HexTooltip } from "../../../hex-components";
import { MarkdownRenderer } from "../../markdown/MarkdownRenderer.js";

export const FormLabelContainer = styled.label`
  display: block;
  width: 75px;
`;

export const FormLabelText = styled.span<{ $fade?: boolean; hasInfo: boolean }>`
  ${({ hasInfo, theme }) =>
    hasInfo
      ? `
        background-image: linear-gradient(to right, ${theme.fontColor.DEFAULT} 80%, transparent 80%);
        background-position: 0 1.15em;
        background-repeat: repeat-x;
        background-size: 4px 1px;
        text-decoration: none;`
      : ""}

  ${({ $fade }) => ($fade ? `opacity: .45;` : "")}

  &:focus {
    border: 1px solid ${({ theme }) => theme.intent.PRIMARY};
  }
`;

const InlineInfoRenderer = styled(MarkdownRenderer)`
  color: ${({ theme }) => theme.fontColor.MUTED};
`;

export interface InputFormLabelProps {
  disabled?: boolean;
  label: string;
  info?: string;
  infoLocation?: "tooltip" | "inline";
  labelForId?: string;
  className?: string;
  placement?: Placement;
}

export const InputFormLabel: React.ComponentType<InputFormLabelProps> =
  React.memo(function InputFormLabel({
    className,
    disabled,
    info,
    infoLocation = "tooltip",
    label,
    labelForId,
    placement = "left",
  }) {
    if (info != null && infoLocation === "inline") {
      return (
        <FormLabelContainer className={className} htmlFor={labelForId}>
          <FormLabelText $fade={disabled} hasInfo={false}>
            {label}
          </FormLabelText>
          <InlineInfoRenderer size="small">{info}</InlineInfoRenderer>
        </FormLabelContainer>
      );
    }

    return (
      <FormLabelContainer className={className} htmlFor={labelForId}>
        <HexTooltip
          autoFocus={false}
          content={
            <MarkdownRenderer size="small">{info ?? ""}</MarkdownRenderer>
          }
          disabled={info == null || disabled}
          enforceFocus={false}
          interactionKind="hover"
          // To allow full view of docs for fields with large descriptions
          maxWidthOverride={240}
          openOnTargetFocus={false}
          placement={placement}
        >
          <FormLabelText $fade={disabled} hasInfo={info != null}>
            {label}
          </FormLabelText>
        </HexTooltip>
      </FormLabelContainer>
    );
  });
