import {
  AppSessionCellId,
  CellExecutionState,
  MAX_NON_METADATA_OUTPUT_COUNT,
  OUTPUTS_TRUNCATED_MIMETYPE,
  OutputContentId,
  OutputId,
  OutputType,
  OutputsTruncatedContents,
  getDateTimeString,
  uuid,
} from "@hex/common";
import { max } from "lodash";
import { useMemo } from "react";
import { shallowEqual } from "react-redux";

import { useAppSessionCellSelector } from "../../appsession-multiplayer/state-hooks/appSessionCellStateHooks";
import {
  AppSessionCellMP,
  OutputMP,
} from "../../redux/slices/appSessionMPSlice";
import { ElementType } from "../elements/ElementType";

import { parseOutputType } from "./parseOutputType";

export interface UseCellDataResult {
  key: string;
  outputFragment: OutputMP;
  type: ElementType | null;
}

export interface UseCellDataArgs {
  appSessionCellId: AppSessionCellId | undefined;
  filterErrors?: boolean;
}

export const useCellLastExecutionTime = (
  appSessionCellId: AppSessionCellId | undefined,
): {
  lastExecutionStartTime: Date | null;
  lastExecutionEndTime: Date | null;
} => {
  const appSessionCell = useAppSessionCellSelector({
    appSessionCellId,
    selector: (cell) => {
      return {
        lastExecutionEndTime: cell?.lastExecutionEndTime,
        lastExecutionStartTime: cell?.lastExecutionStartTime,
      };
    },
    safe: true,
    equalityFn: shallowEqual,
  });

  const lastExecutionStartTimeStr =
    appSessionCell?.lastExecutionStartTime ?? null;
  const lastExecutionEndTimeStr = appSessionCell?.lastExecutionEndTime ?? null;

  return useMemo(
    () => ({
      lastExecutionStartTime:
        lastExecutionStartTimeStr != null
          ? new Date(lastExecutionStartTimeStr)
          : null,
      lastExecutionEndTime:
        lastExecutionEndTimeStr != null
          ? new Date(lastExecutionEndTimeStr)
          : null,
    }),
    [lastExecutionStartTimeStr, lastExecutionEndTimeStr],
  );
};

// TODO: we may want to change useCellData to not use clientside resolvers, and instead have the appSession pass through its appSessionCells down into the cell components
export const useCellData = ({
  appSessionCellId,
  filterErrors = false,
}: UseCellDataArgs): {
  outputs: UseCellDataResult[];
  metadataOutputs: UseCellDataResult[];
  state: CellExecutionState;
  id: string | undefined;
} => {
  const appSessionCell = useAppSessionCellSelector({
    appSessionCellId,
    selector: (cell) => {
      return {
        id: cell?.id,
        outputs: cell?.outputs,
        state: cell?.state,
      };
    },
    safe: true,
    equalityFn: shallowEqual,
  });

  const { metadataOutputs, outputs, state } = useMemo(
    () =>
      getCellData({
        outputs: appSessionCell.outputs,
        state: appSessionCell.state,
        filterErrors,
      }),
    [appSessionCell, filterErrors],
  );

  return useMemo(
    () => ({
      outputs,
      metadataOutputs,
      state: state,
      id: appSessionCell?.id,
    }),
    [appSessionCell?.id, metadataOutputs, outputs, state],
  );
};

export function getCellData({
  filterErrors,
  outputs,
  state,
}: {
  outputs: AppSessionCellMP["outputs"] | undefined;
  state: AppSessionCellMP["state"] | undefined;
  filterErrors?: boolean;
}): {
  outputs: UseCellDataResult[];
  metadataOutputs: UseCellDataResult[];
  state: CellExecutionState;
} {
  const sortedOutputs = [...(outputs ?? [])]
    .filter(
      (output) =>
        (!filterErrors || output.outputType !== OutputType.ERROR) &&
        output.outputType !== OutputType.METADATA &&
        output.deletedDate == null,
    )
    .sort((a, b) => a.order - b.order)
    .map((outputFragment, i) => ({
      key: i.toString(),
      outputFragment,
      type: parseOutputType(outputFragment),
    }));
  const filteredMetadataOutputs = [...(outputs ?? [])]
    .filter(
      (output) =>
        output.outputType === OutputType.METADATA && output.deletedDate == null,
    )
    .map((outputFragment, i) => ({
      key: i.toString(),
      outputFragment,
      type: parseOutputType(outputFragment),
    }));
  const now = getDateTimeString(new Date());
  const totalOutputs =
    max(sortedOutputs.map((output) => output.outputFragment.order)) ?? 0;
  if (!filterErrors && totalOutputs > MAX_NON_METADATA_OUTPUT_COUNT) {
    const contents: OutputsTruncatedContents = {
      totalOutputs,
    };
    sortedOutputs.unshift({
      key: "truncated",
      outputFragment: {
        __typename: "Output",
        exportTypesAndStatus: [],
        frontendOutputContents: [
          {
            __typename: "OutputContent",
            // We have to stringify twice because outputs are automatically deserialized, and we want output renderers to always accept strings
            contents: JSON.stringify(JSON.stringify(contents)),
            id: uuid() as OutputContentId,
            mimeType: OUTPUTS_TRUNCATED_MIMETYPE,
            createdDate: now,
            updatedDate: now,
            deletedDate: null,
            revision: 0,
          },
        ],
        id: uuid() as OutputId,
        order: 1,
        createdDate: now,
        updatedDate: now,
        deletedDate: null,
        revision: 0,
        requestId: null,
        outputType: OutputType.ERROR,
      },
      type: "string",
    });
  }

  return {
    outputs: sortedOutputs,
    metadataOutputs: filteredMetadataOutputs,
    state: state ?? CellExecutionState.STALE,
  };
}
