import React, { ReactNode } from "react";
import { PrismAsync as PrismSyntaxHighlighter } from "react-syntax-highlighter";
import { useTheme } from "styled-components";

export interface SyntaxHighlighterProps {
  allowScroll?: boolean;
  children: ReactNode;
  className?: string;
  language?: string;
  isInAppView?: boolean;
}

/**
 * Forked from https://github.com/nteract/nteract/blob/a96151b5f2d1ae3b7604f82008d9413e1cc5f4c8/packages/presentational-components/src/syntax-highlighter/index.tsx
 */
export const SyntaxHighlighter: React.ComponentType<SyntaxHighlighterProps> =
  React.memo(function SyntaxHighlighter({
    allowScroll = true,
    children,
    className,
    isInAppView = false,
    language = "text",
  }) {
    const theme = useTheme();
    const resolvedLanguage = language.startsWith("text/x-")
      ? language.slice("text/x-".length)
      : language;

    return (
      <PrismSyntaxHighlighter
        className={className}
        language={resolvedLanguage}
        style={theme.markdownCodeTheme(isInAppView, allowScroll)}
      >
        {children}
      </PrismSyntaxHighlighter>
    );
  });
