/**
 * Check if the caret is on the first line of an element
 * Returns `false` when the caret is part of a selection
 *
 * Taken from: https://gist.github.com/loilo/f873a88631e660c59a1d5ab757ca9b1e
 */
export function isCursorOnFirstLine(element: HTMLElement | null) {
  if (!element) return false;
  if (element.ownerDocument.activeElement !== element) return false;

  // Get the client rect of the current selection
  const selection = element.ownerDocument.defaultView?.getSelection();
  if (!selection || selection.rangeCount === 0) return false;

  const originalCaretRange = selection.getRangeAt(0);

  // Bail if there is text selected
  if (originalCaretRange.toString().length > 0) return false;

  const originalCaretRect = originalCaretRange.getBoundingClientRect();

  // Create a range at the end of the last text node
  const startOfElementRange = element.ownerDocument.createRange();
  startOfElementRange.selectNodeContents(element);

  // The endContainer might not be an actual text node,
  // try to find the last text node inside
  let startContainer = startOfElementRange.endContainer;
  const startOffset = 0;

  while (startContainer.hasChildNodes() && !(startContainer instanceof Text)) {
    if (!startContainer.firstChild) continue;

    startContainer = startContainer.firstChild;
  }

  startOfElementRange.setStart(startContainer, startOffset);
  startOfElementRange.setEnd(startContainer, startOffset);
  const endOfElementRect = startOfElementRange.getBoundingClientRect();

  return originalCaretRect.top === endOfElementRect.top;
}

/**
 * Check if the caret is on the last line of an element
 * Returns `false` when the caret is part of a selection
 *
 * Taken from: https://gist.github.com/loilo/f873a88631e660c59a1d5ab757ca9b1e
 */
export function isCursorOnLastLine(element: HTMLElement | null): boolean {
  if (!element) return false;
  if (element.ownerDocument.activeElement !== element) return false;

  // Get the client rect of the current selection
  const window = element.ownerDocument.defaultView;

  if (!window) return false;

  const selection = window.getSelection();

  if (!selection || selection.rangeCount === 0) return false;

  const originalCaretRange = selection.getRangeAt(0);

  // Bail if there is a selection
  if (originalCaretRange.toString().length > 0) return false;

  // If the element is empty, the caret is on the last (and only) line
  if (element.textContent?.trim().length === 0) return true;

  const originalCaretRect = originalCaretRange.getBoundingClientRect();

  // Create a range at the end of the last text node
  const endOfElementRange = document.createRange();
  endOfElementRange.selectNodeContents(element);

  // The endContainer might not be an actual text node,
  // try to find the last text node inside
  let endContainer = endOfElementRange.endContainer;
  let endOffset = 0;

  while (endContainer.hasChildNodes() && !(endContainer instanceof Text)) {
    if (!endContainer.lastChild) continue;

    endContainer = endContainer.lastChild;
    endOffset = (endContainer as Text).length ?? 0;
  }

  endOfElementRange.setEnd(endContainer, endOffset);
  endOfElementRange.setStart(endContainer, endOffset);
  const endOfElementRect = endOfElementRange.getBoundingClientRect();

  return originalCaretRect.bottom === endOfElementRect.bottom;
}
