import { OutputId } from "@hex/common";
import { maxBy } from "lodash";
import moment from "moment";

import { OutputMP } from "../../redux/slices/appSessionMPSlice";

export type OutputContent = OutputMP["frontendOutputContents"][number] & {
  outputId: OutputId;
};

/**
 * All metadata outputs are given the order -1 so the sorting done by
 * useCellData is useless for them. Use this function to get the most recent
 * metadata output of a given mimeType.
 */
export function getMostRecentMetadataOutputContent(
  metadataOutputs: readonly OutputMP[],
  mimeType: string,
): OutputContent | undefined {
  const outputContents = metadataOutputs
    .flatMap((outputFragment) => outputFragment.frontendOutputContents)
    .filter((outputContent) => outputContent.mimeType === mimeType);
  const mostRecentOutputContent = maxBy(outputContents, ({ createdDate }) =>
    moment(createdDate).valueOf(),
  );
  if (mostRecentOutputContent) {
    // mostRecentOutputContent is guaranteed to be in one of metadataOutputs
    // because that's where we pulled it out of
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const output = metadataOutputs.find(({ frontendOutputContents }) =>
      frontendOutputContents.includes(mostRecentOutputContent),
    )!;
    return {
      ...mostRecentOutputContent,
      outputId: output.id,
    };
  }
}
